import { Component, OnInit } from '@angular/core';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { faTwitter, faFacebookSquare, faGoogle, faLinkedin, faInstagram, faFacebookF, faGooglePlus } from '@fortawesome/free-brands-svg-icons';
import { ShopService } from '../../../views/shop/shop.service'
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  public footer : any
  public config : any 
  constructor(
    private ShopService: ShopService
  ) {
    library.add(fas);
    library.add(faTwitter);
    library.add(faFacebookSquare);
    library.add(faGoogle);
    library.add(faLinkedin);
    library.add(faInstagram);
    library.add(faFacebookF);
    library.add(faGooglePlus);
  }

  ngOnInit() {
    this.ShopService.footer().subscribe(data => {
      if (data['status']) {
        this.footer = data['data']
      }
    })
    this.config = JSON.parse(localStorage.getItem('config'));
  }

  url(){
    let config= JSON.parse(localStorage.getItem('config'));
    window.open(config['copyrightlink'], "_blank");
  }
}
