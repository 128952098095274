
import { throwError as observableThrowError, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Product } from '../../shared/models/product.model';
import { FormGroup } from '@angular/forms';

import { of, combineLatest } from 'rxjs';
import { startWith, debounceTime, delay, map, switchMap } from 'rxjs/operators';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { UrlApi } from '../_helperAuth/urlapi';
import { Controller } from '../_helperAuth/controller';
import { Mobigate } from '../_helperAuth/mobigate'
import { BehaviorSubject, Subject } from 'rxjs';
import { AppAlertService } from '../../shared/services/app-alert/app-alert.service';
export interface CartItem {
  product: Product;
  data: {
    quantity: number,
    remark : any,
    total : number
  };
}
@Injectable()
export class ShopService {
  public products: Product[] = [];
  public data = [];
  public initialFilters = {
    minPrice: 0,
    maxPrice: 10000,
    minRating: 0,
    maxRating: 5,
    category: ''
  };

  public cart: CartItem[] = [];
  public cartData = {
    itemCount: 0
  }
  public cartx: any;

  constructor(private http: HttpClient, private route: Router,private alert:AppAlertService) {
  }

  get_controller(ctrl) {
    let Controldata = new Controller();
    let Newctrl = Controldata.Controller(ctrl);
    return Newctrl;
  }
  get_url(url) {
    let Urlapi = new UrlApi();
    let NewUrl = Urlapi.if(url);
    return NewUrl;
  }
  getMobigate(url) {
    let mobigate = new Mobigate();
    let mobigatev2 = mobigate.if(url);
    return mobigatev2;
  }
  getcat(cate) {
    let data = cate.toUpperCase();
    if (cate) {
      this.initialFilters = {
        minPrice: 0,
        maxPrice: 10000,
        minRating: 0,
        maxRating: 5,
        category: data
      };
    }
  }
  sendreview(data) {
    let sid = data['sid'];
    let _id = data['_id'];
    let Url = this.get_url('_getlistitem');
    let ctrl = this.get_controller('review');
    let update = Object.assign({ sid: sid, _id: _id }, ctrl['value']);
    this.http.post(Url, update).subscribe(data => {
    });
  }
  getPopularitem() {
    let Url = this.get_url('_getcategories');
    let ctrl = this.get_controller('getcategoryhotproduct');
    return this.http.post(Url, ctrl['value']);
  }
  //GET Service Method
  getShopdetail() {
    let Url = this.get_url('_getsch');
    let ctrl = this.get_controller('list');
    return this.http.post(Url, ctrl['value'])
  }
  getShop(sname) {
    let Url = this.get_url('_getsch');
    let ctrl = this.get_controller('listbysname');
    let updatectrl = Object.assign({ sname: sname }, ctrl['value']);
    return this.http.post<Product[]>(Url, updatectrl)
  }
  public getCart(): Observable<CartItem[]> {
    return of(this.cart)
  }
  public addToCart(cartItem: CartItem): Observable<CartItem[]> {
    let index = -1;
    this.cart.forEach((item, i) => {
      if (item.product._id === cartItem.product._id) {
        index = i;
      }
    })
    if (index !== -1) {
      let productqtty = cartItem.product['qtty'] - 1 ;
      console.log(productqtty ,this.cart[index].data.quantity )
      if(productqtty  >= this.cart[index].data.quantity){
        this.cart[index].data.quantity += cartItem.data.quantity;
        this.updateCount();
        this.alert.confirm({ message: `Item has been added to your shopping cart` ,alert : 'success'})
        return of(this.cart)
      }else{
        this.alert.confirm({ message: `This item is out of stock` ,alert : 'fail'})
        return of(this.cart)
      }

    } else {
      if(cartItem.product['qtty']  >= cartItem.data.quantity){
        this.cart.push(cartItem);
        this.updateCount();
        this.alert.confirm({ message: `Item has been added to your shopping cart` ,alert : 'success'})
        return of(this.cart)
      }else{
        this.alert.confirm({ message: `This item is out of stock` ,alert : 'fail'})
        return of(this.cart)
      }

    }
  }
  private updateCount() {
    this.cartData.itemCount = 0;
    this.cart.forEach(item => {
      this.cartData.itemCount += item.data.quantity;
    })
  }
  public removeFromCart(cartItem: CartItem): Observable<CartItem[]> {
    this.cart = this.cart.filter(item => {
      if (item.product._id == cartItem.product._id) {
        return false;
      }
      return true;
    });
    this.updateCount();
    return of(this.cart)
  }

  public getProducts(): Observable<Product[]> {
    let Url = this.get_url('_getlistitem');
    let ctrl = this.get_controller('list');
    return this.http.post<Product[]>(Url, ctrl['value'])
      .pipe(
        delay(500),
        map((data: Product[]) => {
          this.products = data['items']
          return data['items']
        })
      )
  }
  public getProductDetails(productID): Observable<Product> {
    let Url = this.get_url('_getlistitem');
    let ctrl = this.get_controller('list');
    return this.http.post<Product[]>(Url, ctrl['value'])
      .pipe(
        delay(500),
        map((data: Product[]) => {
          let product = data['items'].filter(p => p._id === productID)[0];
          if (!product) {
            return observableThrowError(new Error('Product not found!'));
          }
          return product;
        })
      )
  }
  public getCategories(): Observable<any> {
    let Url = this.get_url('_getcategories');
    let ctrl = this.get_controller('getcategory');
    return this.http.post<any>(Url, ctrl['value'])
      .pipe(
        map(data => {
          let x = data['categories']
          let categories = [];
          for (let i = 0; i < x.length; i++) {
            categories.push(x[i].value);
          }
          return categories;
        })
      )
  }
  getCategoriessid(sname): Observable<any> {
    let Url = this.get_url('_getcategories');
    let ctrl = this.get_controller('getcategorysname');
    let updatectrl = Object.assign({ sname: sname }, ctrl['value']);
    return this.http.post<Product[]>(Url, updatectrl)
      .pipe(
        map(data => {
          let x = data['categories']
          let categories = [];
          for (let i = 0; i < x.length; i++) {
            categories.push(x[i].value);
          }
          return categories;
        })
      )
  }
  public Productbyshop(sid): Observable<Product[]> {
    let Url = this.get_url('_getlistitem');
    let ctrl = this.get_controller('list');
    let updatectrl = Object.assign({ sid: sid }, ctrl['value']);
    return this.http.post<Product[]>(Url, updatectrl)
      .pipe(
        delay(500),
        map((data: Product[]) => {
          this.products = data['items']
          return data['items']
        })
      )
  }
  public getFilteredProductbyshop(filterForm: FormGroup, sname): Observable<Product[]> {
    return combineLatest(
      this.Productbyshop(sname),
      filterForm.valueChanges
        .pipe(
          startWith(this.initialFilters),
          debounceTime(400)
        )
    )
      .pipe(
        switchMap(([products, filterData]) => {
          return this.filterProducts(products, filterData);
        })
      )

  }
  public getFilteredProduct(filterForm: FormGroup): Observable<Product[]> {
    return combineLatest(
      this.getProducts(),
      filterForm.valueChanges
        .pipe(
          startWith(this.initialFilters),
          debounceTime(400)
        )
    )
      .pipe(
        switchMap(([products, filterData]) => {
          return this.filterProducts(products, filterData);
        })
      )

  }
  /*
  * If your data set is too big this may raise performance issue.
  * You should implement server side filtering instead.
  */
  private filterProducts(products: Product[], filterData): Observable<Product[]> {
    let filteredProducts = products.filter(p => {
      let isMatch: Boolean;
      let match = {
        search: false,
        caterory: false,
        price: false,
        rating: false
      };
      // Search
      if (
        !filterData.search
        || p.name.toLowerCase().indexOf(filterData.search.toLowerCase()) > -1
        || p.description.indexOf(filterData.search) > -1
        || p.tags.indexOf(filterData.search) > -1
      ) {
        match.search = true;
      } else {
        match.search = false;
      }
      // Category filter
      if (
        filterData.category === p.category
        || !filterData.category
        || filterData.category === 'all'
      ) {
        match.caterory = true;
      } else {
        match.caterory = false;
      }
      // Price filter

      if (
        p.price.sale >= filterData.minPrice
        && p.price.sale <= filterData.maxPrice
      ) {
        match.price = true;
      } else {
        match.price = false;
      }
      // Rating filter
      if (
        p.ratings.rating >= filterData.minRating
        && p.ratings.rating <= filterData.maxRating
      ) {
        match.rating = true;
      } else {
        match.rating = false;
      }

      for (let m in match) {
        if (!match[m]) return false;
      }

      return true;
    })

    return of(filteredProducts)
  }

  // SignIN //

  public signIn(data) {
    let Url = this.get_url('_getlogin');
    let ctrl = this.get_controller('loginclient');
    let updatectrl = Object.assign({}, ctrl['value'], data);
    return this.http.post(Url, updatectrl);
  }
  public signUp(data) {
    let Url = this.get_url('_getregister');
    let ctrl = this.get_controller('registerclient');
    let updatectrl = Object.assign({}, ctrl['value'], data);
    return this.http.post(Url, updatectrl);
  }
  public payment(data) {
    let Url = this.get_url('_generate');
    let ctrl = this.get_controller('ticketcart');
    let updatectrl = Object.assign({}, ctrl['value'], data);
    return this.http.post(Url, updatectrl);
  }
  public getState(): Observable<any> {
    let ctrl = this.get_controller('getstate');
    let Url = this.get_url('_getcategories');
    return this.http.post<any>(Url, ctrl['value'])
      .pipe(
        map(data => {
          let datacategories = data['categories']
          return of(datacategories);
        })
      )
  }
  public updatebilling(data) {
    let Url = this.get_url('_getregister');
    let ctrl = this.get_controller('billingupdate');
    let updatectrl = Object.assign({}, ctrl['value'], data);
    return this.http.post(Url, updatectrl);
  }
  ChangeFormController(data) {
    let Url = this.get_url('_getdetailclient');
    let ctrl = this.get_controller('resetpassword');
    let user = JSON.parse(localStorage.getItem('currentUser'));
    let update = Object.assign({ id: user.id }, ctrl['value'], data);
    return this.http.post<any>(Url, update)
  }
  profilechangeController(data) {
    let Url = this.get_url('_getdetailclient');
    let ctrl = this.get_controller('update');
    let user = JSON.parse(localStorage.getItem('currentUser'));
    let update = Object.assign({ id: user.id, uid: user.uid }, ctrl['value'], data);
    return this.http.post<any>(Url, update)
  }
  searchForm(keyword) {
    let Url = this.get_url('_getlistitem');
    let ctrl = this.get_controller('list');
    let update = Object.assign({ keyword: keyword }, ctrl['value']);
    return this.http.post<any>(Url, update).pipe(
      delay(500),
      map((data: Product[]) => {
        this.products = data['items']
        return data['items']
      })
    )
  }
  footer() {
    let Url = this.get_url('_getfootermall');
    let ctrl = this.get_controller('footer');
    return this.http.post<any>(Url, ctrl['value'])
  }
  shippingprice(weight) {
    let Url = this.get_url('_getshipping');
    let ctrl = this.get_controller('weight');
    let update = Object.assign({ weight: weight }, ctrl['value']);
    //return update;
    return this.http.post<any>(Url, update)
  }
  getConfig() {
    return this.http.get<any>("../assets/dataapi/config.json")
  }
  getTerm() {
    return this.http.get<any>("../assets/dataapi/term.json")
  }
  listtrans() {
    let Url = this.get_url('_getlistorder');
    let ctrl = this.get_controller('findbyuid');
    let user = JSON.parse(localStorage.getItem('currentUser'));
    let update = Object.assign({ uid: user.uid }, ctrl['value']);
    return this.http.post<any>(Url, update)
  }
  listbycode(code) {
    let Url = this.get_url('_getlistitem');
    let ctrl = this.get_controller('listbycode');
    let update = Object.assign({ codeitem: code }, ctrl['value']);
    return this.http.post<any>(Url, update);
  }
  forgetpassword(email) {
    let Url = this.get_url('_getregister');
    let ctrl = this.get_controller('forgetclient');
    let update = Object.assign(ctrl['value'], email);
    return this.http.post<any>(Url, update);
  }
  show() {
    this.route.navigateByUrl('/RefreshComponent', {skipLocationChange: true}).then(()=>
    this.route.navigate(["cart"]));
  }
}
