import { Component, OnInit, AfterViewInit, Renderer2 } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd, ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';

import { RoutePartsService } from "./shared/services/route-parts.service";
import { ThemeService } from './shared/services/theme.service';

import { filter } from 'rxjs/operators';
import { DropdownAnchorDirective } from "./shared/directives/dropdown-anchor.directive"
import { ShopService } from './views/shop/shop.service';

//@ViewChild(DropdownAnchorDirective)  appDropdownToggle : DropdownAnchorDirective;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, AfterViewInit {
    appTitle = '';
    pageTitle = '';

    constructor(
        public title: Title,
        private router: Router,
        private activeRoute: ActivatedRoute,
        private routePartsService: RoutePartsService,
        private themeService: ThemeService,
        private renderer: Renderer2,
        private ShopService: ShopService
    ) { }

    ngOnInit() {
        this.changePageTitle();
        this.ShopService.getConfig().subscribe(data => {
            this.appTitle = "-"+data[0]['title']+"-";
            if (JSON.parse(localStorage.getItem('config'))) {
                localStorage.removeItem('config');
            }
            localStorage.setItem('config', JSON.stringify(data[0]));
        })
    }

    ngAfterViewInit() {
        this.themeService.applyMatTheme(this.renderer)
    }
    changePageTitle() {
        this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((routeChange) => {
            var routeParts = this.routePartsService.generateRouteParts(this.activeRoute.snapshot);
           // this.appTitle = '';
            if (!routeParts.length)
                return this.title.setTitle(this.appTitle);
            // Extract title from parts;
            this.pageTitle = routeParts
                .reverse()
                .map((part) => part.title)
                .reduce((partA, partI) => { return `${partA} > ${partI}` });
            this.pageTitle += `${this.appTitle}`;
            this.title.setTitle(this.pageTitle);
        });
    }

}
