import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { AppAlertService } from '../../../../shared/services/app-alert/app-alert.service';
@Component({
    selector: 'app-forgetpassword',
    templateUrl: './forgetpassword.component.html',
    styleUrls: ['./forgetpassword.component.scss']
})
export class ForgetpasswordComponent implements OnInit {
    signupclass = { 'col-12 col-sm-12 col-md-6 col-lg-6': true };
    public config: any
    public forgetForm: FormGroup;
    
    constructor(
        public dialogRef: MatDialogRef<ForgetpasswordComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private snackBar: MatSnackBar,
        private alert:AppAlertService
    ) { }
    ngOnInit() {
        this.config = JSON.parse(localStorage.getItem('config'));
        this.forgetForm = new FormGroup({
            email: new FormControl('', Validators.required)
        })
    }
    forget() {
        if (this.forgetForm.status == "VALID") {
            setTimeout(() => {
                this.dialogRef.close(this.forgetForm.value)
            }, 1000);
        } else {
            this.alert.confirm({ message: `Email required` ,alert : 'fail'})
        }
    }
}