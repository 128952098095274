import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { ForgetpasswordComponent } from './forgetpassword/forgetpassword.component'
import { AppLoaderService } from '../../../shared/services/app-loader/app-loader.service';
import { ShopService } from '../../../views/shop/shop.service';
import { AppAlertService } from '../../../shared/services/app-alert/app-alert.service';
import { RegisterComponent } from '../login/register/register.component'
import { SuscessregisterComponent } from './successregister/successregister.component'
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  signupclass = { 'col-12 col-sm-12 col-md-6 col-lg-6': true };
  public signinForm: FormGroup;
  public config: any;
  constructor(
    public dialogRef: MatDialogRef<LoginComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private loader: AppLoaderService,
    private sv: ShopService,
    private alert: AppAlertService
  ) { }

  ngOnInit() {
    this.config = JSON.parse(localStorage.getItem('config'));
    this.signinForm = new FormGroup({
      username: new FormControl('', Validators.required),
      password: new FormControl('', Validators.required)
    })
  }

  signin() {
    if (this.signinForm.status == "VALID") {
      setTimeout(() => {
        this.dialogRef.close(this.signinForm.value)
      }, 1000);
    } else {
      this.alert.confirm({ message: `Phone Number or Email required`, alert: 'fail' })
    }
  }
  forget() {
    this.dialogRef.close();
    const dialogRef = this.dialog.open(ForgetpasswordComponent, {});
    dialogRef.afterClosed().subscribe(result => {
      if (!result) {
        // If user press cancel
        return;
      }
      this.loader.open();
      this.sv.forgetpassword(result).subscribe(data => {
        this.loader.close();
        if (data['status']) {
          this.alert.confirm({ message: data['msg'], alert: 'success' })
        } else {
          this.alert.confirm({ message: data['msg'], alert: 'fail' })
        }
      })
    })
  }
  signUp() {
    this.dialogRef.close();
    const dialogRef = this.dialog.open(RegisterComponent, {});
    dialogRef.afterClosed().subscribe(result => {
      if (!result) {
        // If user press cancel
        return;
      }
      this.loader.open();
      this.sv.signUp(result).subscribe(data => {
        this.loader.close();
        if (data['status']) {
          this.dialogRef.close();
          const dialogRef = this.dialog.open(SuscessregisterComponent, {data :{message : data['msg']}});
          dialogRef.afterClosed().subscribe(result => {
            if (!result) {
              // If user press cancel
              return;
            }
            this.loader.open();
            this.sv.signIn(result).subscribe(data => {
              if (data['status']) {
                // this.alert.confirm({ message: data['msg'] ,alert : 'success'})
                localStorage.setItem('currentUser', JSON.stringify(data['data']));
                location.reload();
              } else {
                this.alert.confirm({ message: data['msg'], alert: 'fail' })
              }
              this.loader.close();
            })
          })
        } else {
          this.alert.confirm({ message: data['msg'], alert: 'fail' })
        }
      })
    })
  }
}
