import { of } from "rxjs";

export class Controller {
    public config = JSON.parse(localStorage.getItem('config'));
    Controller(ctrl) {
        let Controldata = {
            controller: ctrl,
            dir: this.config['dir'],
        }
        return of(Controldata);
    }
}