
export class UrlApi {
   public config = JSON.parse(localStorage.getItem('config'));
   public base_url : any
    if(_url : string) {
      this.base_url = this.config['base_url']+"api/marketplace/";
       let array = [
          { name: '_getlogin', value: this.base_url+'get_login.php' },
          { name: '_getlistitem', value: this.base_url+'get_item_for_sale.php' },
          { name: '_getcategories', value: this.base_url+"get_category_list.php" },
          { name: '_getregister', value: this.base_url+"get_register_profile.php" },
          { name: '_getsch', value: this.base_url+"get_sch.php" },
          { name: '_getdetailseller', value: this.base_url+"get_detail_seller.php" },
          { name : '_getdashboard', value : this.base_url+"get_dashboard.php"},
          { name : '_generate', value : this.base_url+"get_generate.php"},
          { name : '_getfootermall', value : this.base_url+"get_footermall.php"},
          { name : '_getdetailclient', value : this.base_url+"get_detail_client.php"},
          { name : '_getshipping', value : this.base_url+"get_shipping.php"},
          { name : '_getlistorder', value : this.base_url+"get_list_order.php"},
       ];
       let url = array.find(i => i.name === _url);
       return url['value'];
    }
 }
