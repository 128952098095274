import { Component, OnInit, Inject } from '@angular/core';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { faTwitter, faFacebookSquare, faGoogle, faLinkedin, faInstagram, faFacebookF, faGooglePlus } from '@fortawesome/free-brands-svg-icons';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { CustomValidators } from 'ng2-validation';
import { ShopService } from '../../shop.service';
import { MatSnackBar } from '@angular/material';
import { Router } from "@angular/router";
import { AppLoaderService } from '../../../../shared/services/app-loader/app-loader.service';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { TermComponent } from 'app/views/shop/login/term/term.component'
@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  signupclass = { 'col-12 col-sm-12 col-md-6 col-lg-6': true };
  public signupForm: FormGroup;
  public config: any;
  constructor(
    private sv: ShopService,
    private snackBar: MatSnackBar,
    private route: Router,
    private loader: AppLoaderService,
    public dialogRef: MatDialogRef<RegisterComponent>,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    library.add(fas);
    library.add(faTwitter);
    library.add(faFacebookSquare);
    library.add(faGoogle);
    library.add(faLinkedin);
    library.add(faInstagram);
    library.add(faFacebookF);
    library.add(faGooglePlus);
  }

  ngOnInit() {
    this.config = JSON.parse(localStorage.getItem('config'));
    const password = new FormControl('', [Validators.required]);
    const confirmPassword = new FormControl('', CustomValidators.equalTo(password));

    this.signupForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
      name: new FormControl('', Validators.required),
      phone: new FormControl('', [Validators.required, Validators.pattern("^[0-9]*$")]),
      password: password,
      confirmPassword: confirmPassword,
      agreed: new FormControl('', (control: FormControl) => {
        const agreed = control.value;
        if (!agreed) {
          return { agreed: true }
        }
        return null;
      })
    })
  }
  signUp() {
    this.signupForm.value.name = this.signupForm.value.name.replace('\'', '\\\'')
    if (this.signupForm.status == "VALID") {
      setTimeout(() => {
        this.dialogRef.close(this.signupForm.value)
      }, 1000);
    }
  }
  term(){
     // this.dialogRef.close();
      const dialogRef = this.dialog.open(TermComponent, {});
      dialogRef.afterClosed().subscribe(result => {
        if (!result) {
          // If user press cancel
          return;
        }
      })
  }

}
