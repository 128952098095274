import { Routes } from '@angular/router';
import { AdminLayoutComponent } from './shared/components/layouts/admin-layout/admin-layout.component';
import { AuthGuard } from './shared/services/auth/auth.guard';

export const rootRouterConfig: Routes = [
  {
    path: '', 
    component: AdminLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '', 
        loadChildren: './views/shop/shop.module#ShopModule', 
        data: { title: 'Marketplace', breadcrumb: 'Marketplace'}
      },{
        path: 'profile', 
        loadChildren: './views/profile/profile.module#ProfileModule', 
        data: { title: 'Profile', breadcrumb: 'PROFILE'}
      }
    ]
  },
  { 
    path: '**', 
    redirectTo: ''
  }
];

