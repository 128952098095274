import { Component, OnInit ,Inject } from '@angular/core';
import { ShopService } from '../../shop.service'
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
@Component({
    selector: 'app-term',
    templateUrl: './term.component.html',
    //styleUrls: ['./term.component.scss']
})
export class TermComponent implements OnInit {
    public term : string;
    constructor(
        private sv : ShopService,
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<TermComponent>,
    ) { }

    ngOnInit(): void { 
        this.sv.getTerm().subscribe(data => {
            this.term = data['term'];
        })
    }
}
