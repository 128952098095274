import { Observable } from 'rxjs';
import { MatDialogRef, MatDialog, MatDialogConfig } from '@angular/material';
import { Injectable } from '@angular/core';

import { AppAlertComponent } from './app-alert.component';

interface confirmData {
  alert?: string,
  message?: string
}

@Injectable()
export class AppAlertService {

  constructor(private dialog: MatDialog) { }

  public confirm(data:confirmData = {}): Observable<boolean> {
    data.alert = data.alert || '';
    data.message = data.message || '';
    let dialogRef: MatDialogRef<AppAlertComponent>;
    dialogRef = this.dialog.open(AppAlertComponent, {
      width: '380px',
      disableClose: false,
      data: {alert: data.alert, message: data.message}
    });
    return dialogRef.afterClosed();
  }
}