
export class Mobigate {
    public config = JSON.parse(localStorage.getItem('config'));
    public base_url : any 
     if(_url : string) {
       this.base_url = this.config['mobigate']+"mobitrans/";
        let array = [
           { name: '_apilisttrans', value: this.base_url+'api_list_trans.php' },
        ];
        let url = array.find(i => i.name === _url);
        return url['value'];
     }
  }