import { MatDialogRef, MAT_DIALOG_DATA ,MatDialog} from '@angular/material';
import { Component, Inject, OnInit } from '@angular/core';
import { LoginComponent } from '../login.component';
import { ShopService } from '../../shop.service';
import { AppLoaderService } from '../../../../shared/services/app-loader/app-loader.service';
import { AppAlertService } from '../../../../shared/services/app-alert/app-alert.service';
@Component({
    selector: 'app-alert',
    template: `
    <div mat-dialog-content>
        <mat-icon class="iconsuccess">check_circle</mat-icon>
        <br>
        <span style="color:white;" [innerHtml]="data.message"></span>
        <br>
        <button mat-raised-button color="primary" style=" margin-top:5px; width:100%;" (click)="signin()">Login Now</button>
    </div>`,
    styles: [`
    .mat-dialog-content {
        margin: -24px;
        padding: 24px;
        background: #323232;
        font-size:1.0625rem;
        text-align:center;
        min-width:18.75rem;
        max-width:25rem
    }
    .iconsuccess{
        color:#3bc72ade!important;
    }
    .iconfail{
        color:#f44336!important;
    }
    .iconinfo{
        color:#3f51b5!important;
    }
    .iconsuccess,.iconfail,.iconinfo{
        font-size:30px;
        padding-bottom:10px; 
        width: 24px;
        height: 24px;
        transform: scale(2);
        display:block;
        margin-right:auto;
        margin-left:auto;
    }
    /deep/.cdk-overlay-dark-backdrop {
        background:none!important;
        box-shadow: none !important;
    }
    /deep/ dialog { 
        box-shadow: none !important;
      }
    `]
})
export class SuscessregisterComponent implements OnInit {

    ngOnInit() {
    }
    constructor(
        public dialogRef: MatDialogRef<SuscessregisterComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dialog: MatDialog,
        private sv: ShopService,
        private loader: AppLoaderService,
        private alert:AppAlertService
    ) { }
    signin(){
        this.dialogRef.close();
        const dialogRef = this.dialog.open(LoginComponent, {
        });
        dialogRef.afterClosed().subscribe(result => {
          if (!result) {
            // If user press cancel
            return;
          }
          this.loader.open();
          this.sv.signIn(result).subscribe(data => {
            if (data['status']) {
             // this.alert.confirm({ message: data['msg'] ,alert : 'success'})
              localStorage.setItem('currentUser', JSON.stringify(data['data']));
              location.reload();
            } else {
              this.alert.confirm({ message: data['msg'] ,alert : 'fail'})
            }
            this.loader.close();
          })
        });
      }
}