import { Component, OnInit, Input, OnDestroy, Renderer2 ,ViewChild} from '@angular/core';
import { NavigationService } from "../../../shared/services/navigation.service";
import { Subscription, of } from 'rxjs';
import { ThemeService } from '../../../shared/services/theme.service';
import { TranslateService } from '@ngx-translate/core';
import { LayoutService } from '../../services/layout.service';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { startWith, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { LoginComponent } from '../../../views/shop/login/login.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AppLoaderService } from '../../../shared/services/app-loader/app-loader.service';
import { ShopService } from '../../../views/shop/shop.service';
import { Router } from "@angular/router";
import { MatSnackBar } from '@angular/material';
import { AppAlertService } from '../../../shared/services/app-alert/app-alert.service';
@Component({
  selector: 'app-header-top',
  templateUrl: './header-top.component.html',
  styleUrls: ['./header-top.component.css']
})

export class HeaderTopComponent implements OnInit, OnDestroy {
  searchCtrl = new FormControl();
  filteredSearch: Observable<string[]>;
  public search: string[] = [];
  layoutConf: any;
  menuItems: any;
  menuItemSub: Subscription;
  egretThemes: any[] = [];
  currentLang = 'en';
  availableLangs = [{
    name: 'English',
    code: 'en',
  }, {
    name: 'Spanish',
    code: 'es',
  }]
  @Input() notificPanel;
  public user: any
  public cart: any;
  public config: any;
  constructor(
    private layout: LayoutService,
    private navService: NavigationService,
    public themeService: ThemeService,
    public translate: TranslateService,
    private renderer: Renderer2,
    private dialog: MatDialog,
    private loader: AppLoaderService,
    private sv: ShopService,
    private route: Router,
    private snackBar: MatSnackBar,
    private alert: AppAlertService
  ) {


  }
  ngOnInit() {
    this.sv.getConfig().subscribe(data => {
      if (JSON.parse(localStorage.getItem('config'))) {
        localStorage.removeItem('config');
      }
      localStorage.setItem('config', JSON.stringify(data[0]));
    })
    this.config = JSON.parse(localStorage.getItem('config'));
    this.user = JSON.parse(localStorage.getItem('currentUser'));
    this.filteredSearch = this.searchCtrl.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value))
      );
    this.layoutConf = this.layout.layoutConf;
    this.egretThemes = this.themeService.egretThemes;
    this.menuItemSub = this.navService.menuItems$
      .subscribe(res => {
        res = res.filter(item => item.type !== 'icon' && item.type !== 'separator');
        let limit = 4
        let mainItems: any[] = res.slice(0, limit)
        if (res.length <= limit) {
          return this.menuItems = mainItems
        }
        let subItems: any[] = res.slice(limit, res.length - 1)
        mainItems.push({
          name: 'More',
          type: 'dropDown',
          tooltip: 'More',
          icon: 'more_horiz',
          sub: subItems
        })
        this.menuItems = mainItems
      })
  }
  ngOnDestroy() {
    this.menuItemSub.unsubscribe()
  }
  setLang() {
    this.translate.use(this.currentLang)
  }
  changeTheme(theme) {
    this.themeService.changeTheme(this.renderer, theme);
  }
  toggleNotific() {
    this.notificPanel.toggle();
  }
  toggleSidenav() {
    if (this.layoutConf.sidebarStyle === 'closed') {
      return this.layout.publishLayoutChange({
        sidebarStyle: 'full'
      })
    }
    this.layout.publishLayoutChange({
      sidebarStyle: 'closed'
    })
  }
  openlogin(): void {
    const dialogRef = this.dialog.open(LoginComponent, {
      panelClass: 'my-full-screen-dialog'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (!result) {
        // If user press cancel
        return;
      }
      this.loader.open();
      this.sv.signIn(result).subscribe(data => {
        this.loader.close();
        if (data['status']) {
          localStorage.setItem('currentUser', JSON.stringify(data['data']));
          this.ngOnInit();
          this.route.navigate(["/marketplace"]);
          location.reload();
        } else {
          this.alert.confirm({ message: data['msg'], alert: 'fail' })
        }
      })
    });
  }
  Signout() {
    this.loader.open();
    localStorage.removeItem('currentUser');
    // this.ngOnInit();
    this.route.navigate(["/marketplace"]);
    this.loader.close();
    setTimeout(() => {
      location.reload();
    }, 1000);

  }
  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.search.filter(option => option.toLowerCase().includes(filterValue));
  }
  goToLink() {
    let config = JSON.parse(localStorage.getItem('config'));
    window.open(config['link_seller'], "_blank");
  }
  searchFunction(event) {
    let searchCtrl;
    if (event.target.value) {
      searchCtrl = event.target.value;
    } else {
      searchCtrl = event.target.innerText;
    }
    this.route.navigateByUrl('/RefrshComponent', { skipLocationChange: true }).then(() =>
      this.route.navigate(["search/", searchCtrl]));

  }
  autocomplete(event) {
    let autocomplete = event.target.value;
    this.sv.searchForm(autocomplete).subscribe(data => {
      this.search = [];
      data.forEach((item, i) => {
        this.search.push(item.name);
        return of(this.search);
      });
    })
  }
  reload() {
    console.log(this.route['url'])
    let url = this.route['url'];
    this.loader.open();
    //this.route.navigate([""])
    if(url == '/'){
      this.sv.show()
      this.route.navigateByUrl('/RefreshComponent', {skipLocationChange: true}).then(()=>
      this.route.navigate(["/"]));
    }else{
      console.log(false)
      this.route.navigate([""])
    }
    setTimeout(() => {
      this.loader.close();
    }, 2000);
  }

}
export interface MessageState {
  number: number;
}