import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Component, Inject, OnInit } from '@angular/core';

@Component({
    selector: 'app-alert',
    template: `
    <div mat-dialog-content>
        <mat-icon class="iconsuccess" *ngIf="data.alert == 'success'">check_circle</mat-icon>
        <mat-icon class="iconfail" *ngIf="data.alert == 'fail'">error</mat-icon>
        <mat-icon class="iconinfo" *ngIf="data.alert == 'info'">info</mat-icon>
        <br>
        <span style="color:white;" [innerHtml]="data.message"></span>
    </div>`,
    styles: [`
    .mat-dialog-content {
        margin: -24px;
        padding: 24px;
        background: #323232;
        font-size:1.0625rem;
        text-align:center;
        min-width:18.75rem;
        max-width:25rem
    }
    .iconsuccess{
        color:#3bc72ade!important;
    }
    .iconfail{
        color:#f44336!important;
    }
    .iconinfo{
        color:#3f51b5!important;
    }
    .iconsuccess,.iconfail,.iconinfo{
        font-size:30px;
        padding-bottom:10px; 
        width: 24px;
        height: 24px;
        transform: scale(2);
        display:block;
        margin-right:auto;
        margin-left:auto;
    }
    /deep/.cdk-overlay-dark-backdrop {
        background:none!important;
        box-shadow: none !important;
    }
    /deep/ dialog { 
        box-shadow: none !important;
      }
    `]
})
export class AppAlertComponent implements OnInit {

    ngOnInit() {
        setTimeout(() => {
            this.dialogRef.close()
        }, 2000);
    }
    constructor(
        public dialogRef: MatDialogRef<AppAlertComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) { }
}